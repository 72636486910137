import SbEditable from "storyblok-react"
import dynamic from "next/dynamic"

// resolve Storyblok components to Next.js components
export const Components = {
  // Atoms
  button: dynamic(() => import("./atoms/StoryblokButton")),
  alert_banner: dynamic(() => import("./atoms/AlertBanner")),
  instagram_feed: dynamic(() => import("./atoms/InstagramFeed")),
  content_with_checkered_border: dynamic(() => import("./atoms/ContentWithCheckeredBorder")),
  title_banner: dynamic(() => import("./atoms/TitleBanner")),
  dual_cta: dynamic(() => import("./atoms/DualCTA")),
  content_section: dynamic(() => import("./atoms/BasicContent")),
  two_column_list: dynamic(() => import("./atoms/TwoColumnList")),
  sale_message: dynamic(() => import("./atoms/SaleMessage")),
  certs_banner: dynamic(() => import("./atoms/CertsBanner")),

  // Molecules
  featured_image: dynamic(() => import("./molecules/FeaturedImage")),
  icon_card_section: dynamic(() => import("./molecules/IconCardSection")),
  image_and_content: dynamic(() => import("./molecules/ImageAndContent")),
  service_card_section: dynamic(() => import("./molecules/ServiceCardSection")),
  team_member_section: dynamic(() => import("./molecules/TeamMemberSection")),
  content_with_2_column_chart: dynamic(() => import("./molecules/ContentWith2ColumnChart")),
  intro_with_image: dynamic(() => import("./molecules/IntroWithImage")),
  intro_with_banner_and_image: dynamic(() => import("./molecules/IntroWithBannerAndImage")),
  two_chart_section: dynamic(() => import("./molecules/TwoChartSection")),
  content_dog: dynamic(() => import("./molecules/ContentDog")),
  webcam_embed: dynamic(() => import("./molecules/WebcamEmbed")),
  pricing_section: dynamic(() => import("./molecules/PricingSection")),
  pricing_chart: dynamic(() => import("./molecules/PricingChart")),
  last_chance_cta_banner: dynamic(() => import("./molecules/LastChanceCTA")),
  location_finder: dynamic(() => import("../FindYourLocation")),
  job_position_cards: dynamic(() => import("./molecules/JobPositionCards")),
  video_grid: dynamic(() => import("./molecules/VideoGrid")),
  testimonial_section: dynamic(() => import("./molecules/Testimonials")),
  content_with_pricing_and_video: dynamic(() => import("./molecules/ContentWithPricingAndVideo")),
  contact_map: dynamic(() => import("./molecules/ContactMapSection")),
  last_chance_cta_banner: dynamic(() => import("./molecules/LastChanceCTA")),
  benefits_chart: dynamic(() => import("./molecules/BenefitsChart")),
  calendly_embed: dynamic(() => import("./molecules/CalendlyEmbed")),
  video_and_content: dynamic(() => import("./molecules/VideoAndContent")),
  video_section: dynamic(() => import("./molecules/VideoSection")),

  // Organisms
  page: dynamic(() => import("./organisms/Page")),
  location: dynamic(() => import("./organisms/Location")),
  pawprint_background_section: dynamic(() => import("./organisms/PawprintBackgroundSection")),
}

const DynamicComponent = ({ blok }) => {
  // check if component is defined above
  if (typeof Components[blok?.component] !== "undefined") {
    const Component = Components[blok.component]
    // wrap with SbEditable for visual editing

    // Set an _editable value to avoid error in SbEditable
    if (blok._editable === null) {
      blok._editable = undefined
    }

    if (typeof window !== "undefined" && window.location.search.includes("_storyblok")) {
      return (
        <SbEditable content={blok}>
          <Component blok={blok} />
        </SbEditable>
      )
    }

    return <Component blok={blok} />
  }

  // Don't display anything (especially in production) unless we have something meaningful to show
  return null
}

export default DynamicComponent
